/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
    "use strict";

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function () {
                // JavaScript to be fired on all pages
                $(document).ready(function () {
                    var data = sessionStorage.getItem("cookieConsent");
                    if (data !== "yes") {
                        setTimeout(function () {
                            $("#cookie-consent").fadeIn(200);
                        }, 1000);
                        $("#close-cookie-consent, #cookie-consent-ok").click(function () {
                            sessionStorage.setItem("cookieConsent", "yes");
                            $("#cookie-consent").fadeOut(200);
                        });
                    }
                });

                $(".hamburger").on("click", function () {
                    $(this).toggleClass("is-active");
                    if ($(this).hasClass("is-active")) {
                        $("header nav").slideDown(500);
                        if ($('.header-students').length > 0) {
                            $(".nav-wrap").slideDown(500);
                        }
                        $("body").addClass("menu-open");
                        // console.log($(".header-mobile").height());
                        $("body").css("height", "100vh");
                    } else {
                        $("header nav").slideUp(500);
                        if ($('.header-students').length > 0) {
                            $(".nav-wrap").slideUp(500);
                        }
                        $("body").removeClass("menu-open");
                        $("body").css("height", "initial");
                    }
                });

                // $("#wpa_label_s").click(function(e) {
                //   $("#hidden-search").addClass("slide");
                //   $("#hidden-search input[type='search']").focus();
                // });

                // $(document).mouseup(function(e) {
                //   var container = $("#hidden-search");
                //   if (!container.is(e.target) && container.has(e.target).length === 0) {
                //     container.removeClass("slide");
                //   }
                // });

                // $(document).on("keydown", function(e) {
                //   if (e.keyCode === 27) {
                //     $("#hidden-search").removeClass("slide");
                //   }
                // });

                $(document).on("ready", function () {
                    if ($("body").hasClass("search")) {
                        $(".header-top__right-cnt #wpa_label_s").val("");
                    }
                });

                $(window).on("load resize", function () {
                    var height = $(".header-top").height();
                    $(".header__bg").css("min-height", height);
                    $(".header__bg").css("height", height);
                });

                $('.toggle-link a').on('click', function () {
                    if ($(this).hasClass('open')) {
                        $(this).removeClass('open');
                        $(this).parent().next().slideUp(400);
                    } else {
                        $(this).addClass('open');
                        $(this).parent().next().slideDown(400);
                    }
                });

                $('.newsroom-filter .filter .mobile-dd').on('click', function () {
                    if ($(this).hasClass('open')) {
                        $(this).removeClass('open');
                        $(this).next().slideUp(400);
                    } else {
                        $(this).addClass('open');
                        $(this).next().slideDown(400);
                    }
                });

                $('.newsroom-post .btn--expand').on('click', function(e){
                    e.preventDefault();
                    if ($(this).hasClass('open')){
                        $(this).removeClass('open');
                        $(this).text('Expand Article');
                        $(this).prev().children('.full').hide();
                        $(this).prev().children('.intro').show();
                    } else {
                        $(this).addClass('open');
                        $(this).text('Collapse Article');
                        $(this).prev().children('.full').show();
                        $(this).prev().children('.intro').hide();
                    }
                });

                function throttled(fn, delay) {
                    var lastCall = 0;
                    return function () {
                        var now = new Date().getTime();
                        if (now - lastCall < delay) {
                            return;
                        }
                        lastCall = now;
                        return fn.apply(undefined, arguments);
                    };
                }

                if ($(window).width() >= 769) {
                    $(window).on("scroll", function (e) {
                        stickyNav(e);
                    });
                }

                var navTop = $("#scroll-past").height();

                function stickyNav() {
                    if (window.scrollY >= navTop) {
                        $("main").css(
                            "padding-top",
                            navTop + $(".header-bottom__list").height() + "px"
                        );
                        $("#sticky-me").addClass("fixed-nav");
                    } else {
                        $("main").css("padding-top", "0");
                        $("#sticky-me").removeClass("fixed-nav");
                    }
                }

                // Scroll To
                (function () {
                    $(".scrollto").on("click", function (e) {
                        e.preventDefault();
                        var section = $(this).attr("href");
                        $("html, body").animate(
                            {
                                scrollTop: $(section).offset().top
                            },
                            1000
                        );
                    });
                })();

                // $("#menu-main-menu .sub-menu").each(function() {
                //   var theWidth = $(this).width() + 30;
                //   var parentWidth =
                //     $(this)
                //       .parent()
                //       .width() + 30;
                //   var ratio = parentWidth / theWidth;
                //   var adjustedRatio = ratio * -100;
                //   var left = 105 + adjustedRatio;
                //   $(this).css("left", -left + "px");
                // });

                $(".header-bottom__listitem--haschildren, .menu-item-has-children").on(
                    "mouseenter",
                    function () {
                        var sublist = $(this).children(".sub-menu");
                        $(sublist).addClass("sublist--active");
                    }
                );
                $(".header-bottom__listitem--haschildren, .menu-item-has-children").on(
                    "mouseleave",
                    function () {
                        var sublist = $(this).children(".sub-menu");
                        $(sublist).removeClass("sublist--active");
                    }
                );

                function removeActive(el) {
                    if ($(el).data("active") === true) {
                        $(el).attr("data-active", false);
                        $(el).removeClass("active");
                    }
                }

                $(".content-picker li").on("mouseenter click", function (e) {
                    e.preventDefault();
                    removeActive($(".content-picker li"));
                    $(this).addClass("active");
                    var theIndex = $(this).data("index");

                    removeActive($(".content-picker__content li"));
                    var showThis = $(".content-picker__content").find(
                        "li[data-index=" + theIndex + "]"
                    );
                    $(showThis).addClass("active");
                    $(showThis).attr("data-active", true);
                });

                function mobileRemove(el) {
                    removeActive($(".content-picker__content li"));
                    var closest = $(el)
                        .closest(".content-picker__content li")
                        .attr("data-active", true);
                    $(".two-columns__left").removeClass("active");
                    $(".two-columns__right").removeClass("active");
                    $(".mobile-trigger").removeClass("active");
                    $(".mobile-trigger")
                        .siblings()
                        .removeClass("active");
                }

                function mobileAdd(el) {
                    $(el).addClass("active");
                    $(el)
                        .closest(".two-columns__left")
                        .addClass("active");
                    $(el)
                        .siblings()
                        .addClass("active");
                    $(el)
                        .parent()
                        .siblings()
                        .addClass("active");
                    var height = $(el)
                        .parent()
                        .siblings()
                        .outerHeight();
                    if ($(window).width() < 990 && $(window).width() > 568) {
                        $(".content-picker__content").css("height", height);
                    }
                }

                $(".mobile-trigger").click(function (e) {
                    e.preventDefault();
                    mobileRemove($(this));
                    mobileAdd($(this));
                    // if (
                    //   $(this)
                    //     .closest("li")
                    //     .attr("data-index") !== "1"
                    // ) {
                    //   $("html, body").animate({ scrollTop: $(this).offset().top }, 1000);
                    // }
                });

                $(window).on("load resize", function () {
                    if ($(window).width() < 990) {
                        $(".content-picker__content")
                            .find("li[data-active=true]")
                            .find(".mobile-trigger")
                            .trigger("click");
                    }

                    if ($(window).width() < 768) {
                        $(".header .tablet-form-trigger").click(function (e) {
                            e.preventDefault();
                            $("header form").addClass("tablet-slide");
                        });
                        $(document).mouseup(function (e) {
                            var form = $(".header form");
                            if (!form.is(e.target) && form.has(e.target).length === 0) {
                                form.removeClass("tablet-slide");
                            }
                        });
                    }

                    if ($(window).width() > 680) {
                        $(".sub-menu").each(function () {
                            var childHeights = 0;
                            $(this)
                                .children()
                                .each(function () {
                                    childHeights += $(this).outerHeight();
                                });
                            $(this).css("height", childHeights);
                        });
                    }

                    if ($(window).width() <= 680) {
                        $(".menu-item-has-children").click(function (e) {
                            e.stopPropagation();
                            $(this).toggleClass("is-active");
                            var sublist = $(this).children(".sub-menu");
                            $(sublist)
                                .stop()
                                .slideToggle();
                            //$("body").css("height", $(".header-mobile").outerHeight() + "px");
                            // if ($(this).hasClass("is-active")) {
                            //   $(sublist).slideDown(500);
                            // } else {
                            //   $(sublist).slideUp(500);
                            // }
                        });
                    }
                });

                $(".accordion__item").click(function () {
                    $(this).toggleClass("accordion__item--open");
                    $(this)
                        .find(".accordion__hidden")
                        .slideToggle("fast");
                });

                $('.js-accordion').accordion();

                // (function() {
                //   $("select").selectric();
                // })();

                var $btnAction = $(".btn--action");
                var innerWrapClass = "inner-wrap";
                var $letter, $letterWrapper, $innerWrapper;

                // Wrap words
                $btnAction.lettering("words");

                // Wrap letters
                $('[class^="word"]').lettering();
                $letter = $btnAction.find('[class^="char"]');

                // Wrap inner to hide overflow on each letter
                $letter.each(function () {
                    $(this).wrapInner("<span>");
                    $letterWrapper = $(this).find("span");

                    $letterWrapper
                        .addClass(innerWrapClass)
                        .attr("data-letter", $(this).text());

                    // Add class to text spaces
                    if ($letterWrapper.data("letter") === " ") {
                        $(this).addClass("letter-space");
                    }
                });

                // Setup animation
                $btnAction.each(function (index, el) {
                    var tl, duration, stagger, anim;

                    $innerWrapper = $(el).find("." + innerWrapClass);
                    tl = new TimelineMax({paused: true});
                    duration = $(el).attr("data-duration") ? $(el).data("duration") : 0.3;
                    stagger = $(el).attr("data-stagger") ? $(el).data("stagger") : 0.03;

                    anim = tl.staggerTo(
                        $innerWrapper,
                        duration,
                        {
                            y: "-110%",
                            ease: Back.easeOut
                        },
                        stagger
                    );

                    el.animation = anim;

                    if ("ontouchstart" in document.documentElement) {
                        return;
                    } else {
                        $(el)
                            .on("mouseenter focusin", function () {
                                this.animation.play();
                            })
                            .on("mouseleave focusout", function () {
                                this.animation.reverse();
                            });
                    }
                });

                objectFitImages(".hero .swiper-slide img");
                objectFitImages();

                // toggle links
                $('.toggle-links .toggle-link a').on('click', function (e) {
                    e.preventDefault();
                    if (!$(this).parent().hasClass('active')) {
                        $('.toggle-links .toggle-link').removeClass('active');
                        $(this).parent().addClass('active');
                        $(this).parent().next().slideDown(300);
                    } else {
                        $(this).parent().removeClass('active');
                        $(this).parent().next().slideUp(300);
                    }
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                if ($('#map').length > 0) {
                    $('.leaflet-marker-icon').attr('alt', 'Map Marker');
                }

                $('select').comboSelect();
                $('.select-field').each(function () {
                    var label = $(this).children('label').attr('for');
                    $('.combo-input').attr('aria-labelledby', label);
                    $('.gfield_select').attr('aria-labelledby', label);
                });

                $(document).on('gform_post_render', function (event, formID) {
                    $('select').comboSelect();
                    $('.select-field').each(function () {
                        var label = $(this).children('label').attr('for');
                        $('.combo-input').attr('aria-labelledby', label);
                        $('.gfield_select').attr('aria-labelledby', label);
                    });

                    // vars
                    var inputs = document.querySelectorAll('input[type="file"]');
                    var $inputs = $(inputs);

                    // functions
                    function multipleFiles($button, filelist) {
                        $button.text(filelist.length + ' Files Selected');
                    }

                    function singleFile($button, file) {
                        $button.text(file.name);
                    }

                    function createFalseInput(i, input) {
                        var $input = $(input);
                        var $button = $('<button class="button file-upload">Select File</button>');
                        $input.before($button);
                        $input.on('change', function () {
                            if (this.files.length > 1) {
                                multipleFiles($button, this.files);
                            } else {
                                singleFile($button, this.files[0]);
                            }
                        });
                    }

                    function onRender() {
                        $inputs.each(createFalseInput);
                    }

                    onRender();

                });
				
				$('header form.search-form').on('submit', function(e) {
					if ($('.search-field', this).val() == '') {
						e.preventDefault();
						
						$('.search-field', this).focus();
						
						return false;
					}
					
					return true;
				});

            }
        },

        // Home page
        page_template_template_home: {
            init: function () {
                var heroSwiper = new Swiper("#home-swiper", {
                    // loop: true,
                    pagination: {
                        el: ".swiper-pagination"
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                });
                $(".tri-container__item").on("mouseenter", function () {
                    var strikethru = $(this).children(".strikethrough");
                    $(strikethru).addClass("animate-me");
                });
                $(".tri-container__item").on("mouseleave", function () {
                    var strikethru = $(this).children(".strikethrough");
                    $(strikethru).removeClass("animate-me");
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        page_template_template_membership: {
            init: function () {
                var collabSwiper = new Swiper("#collaboration", {
                    // Optional parameters
                    loop: true,
                    slidesPerView: 1,
                    navigation: {
                        nextEl: "#collaboration .swiper-button-next",
                        prevEl: "#collaboration .swiper-button-prev"
                    },
                    pagination: {
                        el: "#collaboration .swiper-pagination",
                        type: "fraction",
                        renderFraction: function (currentClass, totalClass) {
                            return (
                                '<span class="' +
                                currentClass +
                                '"></span>' +
                                " of " +
                                '<span class="' +
                                totalClass +
                                '"></span>'
                            );
                        }
                    }
                });
            }
        },
        page_template_template_who_we_are: {
            init: function () {
                var historySwiper = new Swiper("#history", {
                    slidesPerView: 2.5,
                    spaceBetween: 30,
                    slidesOffsetAfter: 432.5,
                    pagination: {
                        el: ".swiper-pagination",
                        type: "progressbar"
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    },
                    on: {
                        transitionStart: function () {
                            if ($(".swiper-button-next").hasClass("swiper-button-disabled")) {
                                $(".swiper-slide:last-of-type").addClass("swiper-slide-active");
                            }
                        }
                    },
                    breakpoints: {
                        568: {
                            slidesPerView: 1,
                            spaceBetween: 0,
                            slidesOffsetAfter: 0,
                            pagination: {
                                el: ".swiper-pagination",
                                type: "fraction",
                                renderFraction: function (currentClass, totalClass) {
                                    return (
                                        '<span class="' +
                                        currentClass +
                                        '"></span>' +
                                        " of " +
                                        '<span class="' +
                                        totalClass +
                                        '"></span>'
                                    );
                                }
                            }
                        }
                    }
                });
            }
        },
        page_template_template_programs_landing: {
            init: function () {
                $(".tri-container__item").on("mouseenter", function () {
                    var strikethru = $(this).children(".strikethrough");
                    $(strikethru).addClass("animate-me");
                });
                $(".tri-container__item").on("mouseleave", function () {
                    var strikethru = $(this).children(".strikethrough");
                    $(strikethru).removeClass("animate-me");
                });
            }
        },
        page_template_template_signin: {
            init: function () {
                // REMOVE/RECONFIG FOR REAL ENVIRONMENT
                $("button.button").click(function (e) {
                    e.preventDefault();
                    window.location.href =
                        "https://frontend.eiia.sandbox5.cliquedomains.com/dashboard-announcements.html";
                });
            }
        },
        page_template_template_campus_conduct: {
            init: function () {
                var collabSwiper = new Swiper("#conduct-slider", {
                    // Optional parameters
                    loop: true,
                    slidesPerView: 1,
                    navigation: {
                        nextEl: "#conduct-slider .swiper-button-next",
                        prevEl: "#conduct-slider .swiper-button-prev"
                    },
                    pagination: {
                        el: "#conduct-slider .swiper-pagination",
                        type: "fraction",
                        renderFraction: function (currentClass, totalClass) {
                            return (
                                '<span class="' +
                                currentClass +
                                '"></span>' +
                                " of " +
                                '<span class="' +
                                totalClass +
                                '"></span>'
                            );
                        }
                    }
                });
            }
        },
        page_template_template_keystone: {
            init: function () {
                var collabSwiper = new Swiper("#keystone-slider", {
                    // Optional parameters
                    loop: true,
                    slidesPerView: 1,
                    navigation: {
                        nextEl: "#keystone-slider .swiper-button-next",
                        prevEl: "#keystone-slider .swiper-button-prev"
                    },
                    pagination: {
                        el: "#keystone-slider .swiper-pagination",
                        type: "fraction",
                        renderFraction: function (currentClass, totalClass) {
                            return (
                                '<span class="' +
                                currentClass +
                                '"></span>' +
                                " of " +
                                '<span class="' +
                                totalClass +
                                '"></span>'
                            );
                        }
                    }
                });
            }
        },
        page_template_template_risk_managment: {
            init: function () {
                var riskOne = new Swiper("#risk-slides-one", {
                    // Optional parameters
                    loop: true,
                    slidesPerView: 1,
                    navigation: {
                        nextEl: "#risk-slides-one .swiper-button-next",
                        prevEl: "#risk-slides-one .swiper-button-prev"
                    },
                    pagination: {
                        el: "#risk-slides-one .swiper-pagination",
                        type: "fraction",
                        renderFraction: function (currentClass, totalClass) {
                            return (
                                '<span class="' +
                                currentClass +
                                '"></span>' +
                                " of " +
                                '<span class="' +
                                totalClass +
                                '"></span>'
                            );
                        }
                    }
                });

                var riskTwo = new Swiper("#risk-slides-two", {
                    slidesPerView: "auto",
                    loopedSlides: 3,
                    centeredSlides: true,
                    spaceBetween: 30,
                    // initialSlide: 1,
                    // slidesOffsetAfter: 432.5,
                    loop: true,
                    pagination: {
                        el: "#risk-slides-two .swiper-pagination",
                        type: "fraction",
                        renderFraction: function (currentClass, totalClass) {
                            return (
                                '<span class="' +
                                currentClass +
                                '"></span>' +
                                " of " +
                                '<span class="' +
                                totalClass +
                                '"></span>'
                            );
                        }
                    },
                    navigation: {
                        nextEl: "#risk-slides-two .swiper-button-next",
                        prevEl: "#risk-slides-two .swiper-button-prev"
                    },
                    breakpoints: {
                        767: {
                            loopedSlides: 1,
                            slidesPerView: 1,
                            spaceBetween: 0,
                            slidesOffsetAfter: 0,
                            pagination: {
                                el: "#risk-slides-two .swiper-pagination",
                                type: "fraction",
                                renderFraction: function (currentClass, totalClass) {
                                    return (
                                        '<span class="' +
                                        currentClass +
                                        '"></span>' +
                                        " of " +
                                        '<span class="' +
                                        totalClass +
                                        '"></span>'
                                    );
                                }
                            }
                        }
                    }
                });
            }
        },
        page_template_template_campus_hotline: {
            init: function () {
                var riskOne = new Swiper("#campus-hotline", {
                    // Optional parameters
                    loop: true,
                    slidesPerView: 1,
                    navigation: {
                        nextEl: "#campus-hotline .swiper-button-next",
                        prevEl: "#campus-hotline .swiper-button-prev"
                    },
                    pagination: {
                        el: "#campus-hotline .swiper-pagination",
                        type: "fraction",
                        renderFraction: function (currentClass, totalClass) {
                            return (
                                '<span class="' +
                                currentClass +
                                '"></span>' +
                                " of " +
                                '<span class="' +
                                totalClass +
                                '"></span>'
                            );
                        }
                    }
                });
            }
        },
        page_template_template_careers: {
            init: function () {
                $('.js-accordion .scrollto').on('click', function (e) {
                    var $this = $(this);
                    var selectField = $('#field_5_5');
                    var optionItem = selectField.find('.combo-dropdown .option-item');
                    var careerType = $(this).parents('.js-accordion__panel').find('.js-accordion__header').text();

                    selectField.find('option[value="' + careerType + '"]').attr('selected', true);
                    optionItem.each(function () {
                        $(this).removeClass('option-selected').addClass('option-hover');
                    });
                    selectField.find('.combo-dropdown .option-item[data-value="' + careerType + '"]').removeClass('option-hover').addClass('option-selected');
                });
            }
        },
        page_template_template_newsroom: {
            init: function () {

                var lm = AJAX_LOAD_MORE;
                var postUrl = lm.postsEP;
                var searchUrl = lm.searchEP;
                var perPage = 6;
                var getPage = 2;
                var postExclude = lm.exclude;
                var loadMore = lm.loadMoreText;
                var button = $('.load-more');
                var newsContainer = $('#news-article-container');
                var postsHeadline = $('.posts__headline');
                var maxPages;
                var catId;
                var dataArray = {
                    per_page: perPage,
                };

                function postMarkup(data) {
                    // Content
                    var cat = data.categories ? '<p class="section__subheadline">' + data.categories + '</p>' : null;
                    var title = '<h3 class="post-title">' + data.title + '</h3>';
                    var excerpt = data.post_excerpt ? '<div class="excerpt">' + data.post_excerpt + '</div>' : null;
                    var readMore = '<a href="' + data.permalink + '" class="btn btn--action btn--two" data-duration="0.4" data-stagger="0.018" target="">Read More</a>';

                    // Assets
                    var image = data.feat_img ? '<div class="thumb">' + data.feat_img + '</div>' : null;
                    var videoOverlay = data.post_format === 'video' ? '<a href="%s" class="play">Play</a>' : null;

                    // Buildouts
                    var feat_image = image ? '<div class="thumb">' + videoOverlay + image + '</div>' : null;
                    var content = '<div class="content">' + cat + title + excerpt + readMore + '</div>';

                    return '<div class="newsroom-post">' + feat_image + content + '</div>';
                }

                function loadPosts(url, data, opts) {

                    $.ajax({
                        url: url,
                        dataType: 'json',
                        data: data.dataArray,
                        beforeSend: function () {
                            // Let the user know we're grabbing the posts
                            opts.button ? opts.button.text('Loading...') : null;
                        },
                        success: function (data, status, xhr) {
                            // Setup our var conditional since we're fetching from a WP endpoint
                            // or a custom endpoint
                            var posts = data.posts !== undefined ? data.posts : data;

                            // Set out max number of pages
                            maxPages = data.max_pages !== undefined ? data.max_pages : maxPages;
                            // Hide the Load More button if we've reached all our posts
                            button && getPage === maxPages ? button.hide() : button.show();
                            button ? button.text(opts.text) : null;

                            // Run through our results and set them to our HTML
                            if ((posts && !posts.no_results ) && status === 'success') {
                                var post_return = [];

                                // Pass our data to the HTML markup
                                posts.forEach(function (post) {
                                    post_return.push(postMarkup(post));
                                });

                                // Append our results
                                newsContainer.append(post_return);

                                // Increase our pagination
                                getPage++;
                            }
                            else if (posts.no_results && status === 'success') {
                                newsContainer.html('<h3>' + posts.no_results + '</h3>');
                                button.hide();
                            }
                        },
                        error: function () {

                        }
                    });
                }

                // Load More click
                button.on('click', function (e) {
                    e.preventDefault();

                    postExclude ? dataArray.exclude = postExclude : null;
                    // Setup our pagination
                    dataArray.page = getPage;

                    // If there's a catID, we'll need to reset the HTML and
                    // set the category to our params
                    catId ? dataArray.categories = catId : null;
                    catId ? $('#news-article-container').empty() : null;

                    // Our AJAX call
                    loadPosts(postUrl, {getPage: getPage, dataArray: dataArray}, {text: loadMore});
                });

                // Category Link Click
                $('.category-link').on('click', function (e) {
                    e.preventDefault();

                    // Set our param for the category
                    catId = $(this).data('id') ? $(this).data('id') : null;
                    catId !== null && dataArray.exclude ? delete dataArray.exclude : null;
                    catId !== null ? dataArray.categories = catId : delete dataArray.categories;

                    // Set our pagination
                    dataArray.page = getPage = 1;

                    // Adjust the elements
                    $('.featured__headline, .featured-release').hide();
                    $('#news-article-container').empty();
                    postsHeadline.text($(this).text() + ' Posts');

                    // Our AJAX call
                    loadPosts(postUrl, {getPage: getPage, dataArray: dataArray}, {text: loadMore});
                });

                // Our Form Query
                $('#post_search').on('submit', function (e) {
                    e.preventDefault();

                    // Get our search term
                    var input = $(this).find('.search-field').val().toLowerCase();

                    // Setup our data to pass. We need to reset dataArray to ensure the correct
                    // items are passed
                    getPage = 1;
                    dataArray = {
                        per_page: perPage,
                        page: getPage,
                    };

                    // A conditional to check if anything was entered in the input field
                    // Then adjust the return for the headline based on the input
                    input.length ? dataArray.s = input : null;
                    var returnHeadline = input.length ? 'Search Results For &ldquo;<span class="autocaps" style="text-transform: capitalize;">' + input + '</span>&rdquo;' : 'No Search Results';

                    // Adjust the elements
                    $('.featured__headline, .featured-release').hide();
                    $('#news-article-container').empty();
                    postsHeadline.html(returnHeadline);

                    // Our AJAX call
                    loadPosts(searchUrl, {getPage: getPage, dataArray: dataArray}, {text: loadMore});

                });

            }

        },
        blog: {
            init: function () {

                var lm = AJAX_LOAD_MORE;
                var postUrl = lm.postsEP;
                var searchUrl = lm.searchEP;
                var perPage = 6;
                var getPage = 2;
                var postExclude = lm.exclude;
                var loadMore = lm.loadMoreText;
                var button = $('.load-more');
                var newsContainer = $('#news-article-container');
                var postsHeadline = $('.posts__headline');
                var toggleElms = $('.featured__headline, .featured-release');
                var maxPages, catId, input, returnHeadline;
                var dataArray = {
                    per_page: perPage,
                };

                function postMarkup(data) {
                    // Content
                    var cat = data.categories ? '<p class="section__subheadline">' + data.categories + '</p>' : null;
                    var title = '<h3 class="post-title">' + data.title + '</h3>';
                    var excerpt = data.post_excerpt ? '<div class="excerpt">' + data.post_excerpt + '</div>' : null;
                    var readMore = '<a href="' + data.permalink + '" class="btn btn--action btn--two" data-duration="0.4" data-stagger="0.018" target="">Read More</a>';

                    // Assets
                    var image = data.feat_img ? '<div class="thumb">' + data.feat_img + '</div>' : null;
                    var videoOverlay = data.post_format === 'video' ? '<a href="%s" class="play">Play</a>' : null;

                    // Buildouts
                    var feat_image = image ? '<div class="thumb">' + videoOverlay + image + '</div>' : null;
                    var content = '<div class="content">' + cat + title + excerpt + readMore + '</div>';

                    return '<div class="newsroom-post">' + feat_image + content + '</div>';
                }

                function loadPosts(url, data, opts) {

                    $.ajax({
                        url: url,
                        dataType: 'json',
                        data: data.dataArray,
                        beforeSend: function () {
                            // Let the user know we're grabbing the posts
                            opts.button ? opts.button.text('Loading...') : null;
                        },
                        success: function (data, status, xhr) {
                            // Setup our var conditional since we're fetching from a WP endpoint
                            // or a custom endpoint
                            var posts = data.posts !== undefined ? data.posts : data;

                            // Set out max number of pages
                            maxPages = data.max_pages !== undefined ? data.max_pages : maxPages;
                            // Hide the Load More button if we've reached all our posts
                            button && getPage === maxPages ? button.hide() : button.show();
                            button ? button.text(opts.text) : null;

                            // Run through our results and set them to our HTML
                            if ((posts && !posts.no_results ) && status === 'success') {
                                var post_return = [];

                                // Pass our data to the HTML markup
                                posts.forEach(function (post) {
                                    post_return.push(postMarkup(post));
                                });

                                // Append our results
                                newsContainer.append(post_return);

                                // Increase our pagination
                                getPage++;
                            }
                            else if (posts.no_results && status === 'success') {
                                newsContainer.html('<h3>' + posts.no_results + '</h3>');
                                button.hide();
                            }
                        },
                        error: function () {

                        }
                    });
                }

                // Load More click
                button.on('click', function (e) {
                    e.preventDefault();

                    postExclude ? dataArray.exclude = postExclude : null;
                    // Setup our pagination
                    dataArray.page = getPage;

                    // If there's a catID, we'll need to reset the HTML and
                    // set the category to our params
                    catId ? dataArray.categories = catId : null;
                    catId ? $('#news-article-container').empty() : null;

                    // Our AJAX call
                    loadPosts(postUrl, {getPage: getPage, dataArray: dataArray}, {text: loadMore});
                });

                // Category Link Click
                $('.category-link').on('click', function (e) {
                    e.preventDefault();

                    // Set our param for the category
                    catId = $(this).data('id') ? $(this).data('id') : null;
                    catId !== null && dataArray.exclude ? delete dataArray.exclude : null;
                    catId !== null ? dataArray.categories = catId : delete dataArray.categories;

                    // Set our pagination
                    dataArray.page = getPage = 1;

                    // Adjust the elements
                    var headlineText = catId !== null ? $(this).text() + ' Posts' : 'New Posts';
                    catId !== null ? toggleElms.hide() : toggleElms.removeAttr('style');
                    $('#news-article-container').empty();
                    postsHeadline.text(headlineText);

                    // Our AJAX call
                    loadPosts(postUrl, {getPage: getPage, dataArray: dataArray}, {text: loadMore});
                });

                // Our Form Query
                $('#post_search').on('submit', function (e) {
                    e.preventDefault();

                    // Get our search term
                    var input = $(this).find('.search-field').val().toLowerCase();

                    // Setup our data to pass. We need to reset dataArray to ensure the correct
                    // items are passed
                    getPage = 1;
                    dataArray = {
                        per_page: perPage,
                        page: getPage,
                    };

                    // A conditional to check if anything was entered in the input field
                    // Then adjust the return for the headline based on the input
                    input.length ? dataArray.s = input : null;
                    returnHeadline = input.length ? 'Search Results For &ldquo;<span class="autocaps" style="text-transform: capitalize;">' + input + '</span>&rdquo;' : 'No Search Results';

                    // Adjust the elements
                    $('.featured__headline, .featured-release').hide();
                    $('#news-article-container').empty();
                    postsHeadline.html(returnHeadline);

                    // Our AJAX call
                    loadPosts(searchUrl, {getPage: getPage, dataArray: dataArray}, {text: loadMore});

                });

            }
        },
        single_institution: {
            init: function () {
                if($('#institution-form').length > 0) {
                    iFrameResize({autoResize: true}, '#institution-form');
                }
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (i,
                                                                                      classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, "finalize");
            });

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
